.initial {
  &__desc {
    margin-bottom: 16px;
  }

  &__desc,
  &__form {
    @include media("<=desktop-sm") {
      display: none;
    }
  }

  &__mobile {
    padding-right: 16px;
    position: relative;
    top: 96px;
    width: 100%;

    @include media(">desktop-sm") {
      display: none;
    }

    .c-section {
      &:not(:last-child) {
        padding-left: 48px;
        margin-bottom: 48px;
      }
    }

    .c-marquee {
      width: fit-content;
      position: relative;
      will-change: transform;
      display: flex;
      animation: marquee 25s linear infinite;
      animation-play-state: running;

      &__section {
        &:not(:first-of-type) {
          padding: 0 1px;
        }
      }

      &__container {
        @extend %ovh;
        width: 100%;
      }
    }
  }

  &__block {
    &.active {
      opacity: 1;
    }
  }
}

@keyframes marquee {
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}
