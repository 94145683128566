%page {
  height: 100%;
  left: 0;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  top: 0;
  visibility: hidden;
  width: 100%;

  &:not(&.initial) {
    @include media("<=desktop-sm") {
      display: none;
    }
  }
}

%page--active {
  opacity: 1;
  pointer-events: auto;
  transition: none;
  visibility: visible;
  height: 100%;
  overflow: unset;
}

%content {
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  will-change: transform;
}

%desktop {
  @include media("<=phone") {
    display: none;
  }
}

%desktop-tab {
  @include media("<=tablet") {
    display: none;
  }
}

%tablet {
  @include media(">tablet") {
    display: none;
  }
}

%mobile {
  @include media(">phone") {
    display: none;
  }
}

%ml-32 {
  margin-left: 32px;
}

.c-section {
  &__label {
    text-transform: uppercase;
    margin-bottom: 16px;
  }

  &__content {
    transition-delay: 125ms;
    margin-left: 16px;
    opacity: inherit;
  }

  &__footer {
    margin-top: 48px;
  }

  &__line {
    max-width: 484px;
    width: 100%;
    overflow: hidden;
  }

  &__copyright {
    margin: 16px 0;
  }
}
