.c-form {
  position: relative;
  display: flex;

  .placeholder {
    position: absolute;
    bottom: 0;
    left: 16px;
    animation-duration: 0.5s;
    display: none;
  }

  .c-input {
    font-family: $font-sans;
    color: $yellow;
    background: transparent;
    font-size: 12px;
    line-height: 1.3;
    outline: none;
    border: 0;
    caret-color: transparent;
    width: 100%;

    &.blink {
      & + .placeholder {
        display: block;
      }
    }

    @include autoFill(transparent, $yellow);
    @include placeholder {
      color: $yellow;
      font-size: inherit;
      font-family: inherit;
    }
  }
}
