@import "utils/breakpoints";
@import "utils/mixins";
@import "utils/functions";

@import "core/variables";
@import "core/reset";
@import "core/globals";
@import "core/fonts";

@import "shared/links";
@import "shared/layout";
@import "shared/sections";

@import "components/nav";
@import "components/header";
@import "components/preloader";
@import "components/sidebar";
@import "components/links";
@import "components/form";
@import "components/experience";
@import "components/projects";
@import "components/everything";

@import "pages/initial";
@import "pages/page";
