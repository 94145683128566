.page {
  @extend %cover;
  @extend %page;

  &--active {
    @extend %page--active;
  }

  &__wrapper {
    @include media(">desktop-sm") {
      padding-bottom: 160px;
    }
  }
}
