.c-nav {
  text-transform: uppercase;
  margin-bottom: 32px;
  @include media("<=desktop-sm") {
    display: none;
  }

  &__grid {
    display: flex;
  }

  &__column {
    max-width: 172px;
    width: 100%;
  }
}
