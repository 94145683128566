.c-links {
  &__label {
    text-transform: uppercase;
    margin-bottom: 16px;

    .mobile {
      @extend %tablet;
    }

    .desktop {
      @extend %desktop-tab;
    }
  }

  &__items {
    margin-left: 16px;
  }
}

.c-link {
  line-height: 1;
  display: inline-block;
  background: $link-bg;
  padding: 3px 2px;
  padding-top: 1px;
  border-radius: 2px;

  &:hover {
    background: transparent;
  }

  &__parent {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}
