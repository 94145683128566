.c-header {
  display: flex;

  &__left {
    margin-right: 17px;
  }

  &__right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    text-transform: uppercase;
  }

  &__item {
  }

  &__time,
  &__date {
    text-align: right;
  }
}
