@import "https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap";
.page, .c-sidebar {
  height: 100%;
  object-fit: cover;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  background: none;
  border: 0;
  padding: 0;
  font-family: inherit;
}

a {
  font-family: inherit;
  text-decoration: none;
}

*, :before, :after {
  box-sizing: border-box;
  -webkit-touch-callout: none;
  outline: none;
}

a, button {
  color: inherit;
  text-decoration: none;
}

ol, ul {
  list-style: none;
}

html {
  box-sizing: border-box;
  height: 100%;
  overscroll-behavior: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  font-size: .694444vw;
}

@media (max-width: 425px) {
  html {
    font-size: 2.66667vw;
  }
}

body {
  height: 100%;
  overscroll-behavior: none;
  width: 100%;
  color: #e6c72e;
  background: #0d0c08;
  margin: 0;
  padding: 0;
  font-family: Roboto Mono, monospace;
  font-size: 12px;
  line-height: 1.3;
}

@media (min-width: 951px) {
  .c-container {
    max-width: 484px;
  }
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-32 {
  margin-bottom: 32px;
}

.content, .content .pages {
  position: relative;
}

@media (min-width: 951px) {
  .content {
    height: 100%;
    z-index: 8;
    max-width: 72rem;
    width: calc(100% - 580px);
    margin-left: auto;
    padding-top: 32px;
    padding-right: 32px;
  }
}

.flash {
  animation: blinker 1s step-start infinite;
}

.c-characters {
  -webkit-touch-callout: none;
  user-select: none;
  -khtml-user-select: none;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.initial__mobile .c-marquee__container {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
}

.initial__mobile .c-marquee__container img, .initial__mobile .c-marquee__container video {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  overflow: hidden;
}

.page {
  height: 100%;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

@media (max-width: 950px) {
  .page:not(.initial.page) {
    display: none;
  }
}

.page--active {
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
  height: 100%;
  overflow: unset;
  transition: none;
}

@media (max-width: 768px) {
  .c-links__label .desktop {
    display: none;
  }
}

@media (min-width: 769px) {
  .c-links__label .mobile {
    display: none;
  }
}

.c-sidebar__links, .c-sidebar__about, .c-sidebar__characters {
  margin-left: 32px;
}

.c-section__label {
  text-transform: uppercase;
  margin-bottom: 16px;
}

.c-section__content {
  opacity: inherit;
  margin-left: 16px;
  transition-delay: .125s;
}

.c-section__footer {
  margin-top: 48px;
}

.c-section__line {
  max-width: 484px;
  width: 100%;
  overflow: hidden;
}

.c-section__copyright {
  margin: 16px 0;
}

.c-nav {
  text-transform: uppercase;
  margin-bottom: 32px;
}

@media (max-width: 950px) {
  .c-nav {
    display: none;
  }
}

.c-nav__grid {
  display: flex;
}

.c-nav__column {
  max-width: 172px;
  width: 100%;
}

.c-header {
  display: flex;
}

.c-header__left {
  margin-right: 17px;
}

.c-header__right {
  width: 100%;
  text-transform: uppercase;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.c-header__time, .c-header__date {
  text-align: right;
}

.preloader {
  width: 100%;
  height: 100%;
  z-index: 10;
  background: #0d0c08;
  padding: 32px;
  position: fixed;
  top: 0;
  left: 0;
}

.preloader__block {
  opacity: 0;
}

.preloader__block.active {
  opacity: 1;
}

.c-sidebar {
  z-index: 7;
  padding-top: 32px;
  padding-left: 32px;
  position: fixed;
}

@media (max-width: 950px) {
  .c-sidebar {
    height: 80px;
    background: linear-gradient(#0d0c08 0%, #0d0c0800 100%);
    padding: 16px;
  }
}

.c-sidebar__characters {
  overflow-wrap: break-word;
}

@media (max-width: 950px) {
  .c-sidebar__characters {
    display: none;
  }
}

.c-sidebar__nav {
  opacity: 0;
  transition-delay: .25s;
}

@media (min-width: 951px) {
  .c-sidebar__nav {
    transition-delay: unset;
    margin-bottom: 32px;
  }
}

.c-sidebar__about {
  margin-top: 32px;
}

@media (max-width: 950px) {
  .c-sidebar__about {
    display: none;
  }
}

.c-sidebar__links {
  margin-top: 48px;
}

@media (max-width: 950px) {
  .c-sidebar__links {
    display: none;
  }
}

.c-links__label {
  text-transform: uppercase;
  margin-bottom: 16px;
}

.c-links__items {
  margin-left: 16px;
}

.c-link {
  background: #171405;
  border-radius: 2px;
  padding: 1px 2px 3px;
  line-height: 1;
  display: inline-block;
}

.c-link:hover {
  background: none;
}

.c-link__parent:not(:last-child) {
  margin-bottom: 16px;
}

.c-form {
  display: flex;
  position: relative;
}

.c-form .placeholder {
  animation-duration: .5s;
  display: none;
  position: absolute;
  bottom: 0;
  left: 16px;
}

.c-form .c-input {
  color: #e6c72e;
  caret-color: #0000;
  width: 100%;
  background: none;
  border: 0;
  outline: none;
  font-family: Roboto Mono, monospace;
  font-size: 12px;
  line-height: 1.3;
}

.c-form .c-input.blink + .placeholder {
  display: block;
}

.c-form .c-input:-webkit-autofill, .c-form .c-input:-webkit-autofill:hover, .c-form .c-input:-webkit-autofill:focus, .c-form .c-input:-webkit-autofill:active {
  -webkit-text-fill-color: #e6c72e;
  box-shadow: inset 0 0 0 30px #0000;
}

.c-form .c-input.placeholder {
  color: #e6c72e;
  font-size: inherit;
  font-family: inherit;
}

.c-form .c-input::-webkit-input-placeholder {
  color: #e6c72e;
  font-size: inherit;
  font-family: inherit;
}

.c-form .c-input::-moz-placeholder {
  color: #e6c72e;
  font-size: inherit;
  font-family: inherit;
}

.c-form .c-input:-moz-placeholder {
  color: #e6c72e;
  font-size: inherit;
  font-family: inherit;
}

.c-form .c-input:-ms-input-placeholder {
  color: #e6c72e;
  font-size: inherit;
  font-family: inherit;
}

.c-experience .c-resume:not(:last-child) {
  margin-bottom: 16px;
}

.c-projects .c-project:not(:last-child) {
  margin-bottom: 32px;
}

.c-everything .c-section:not(:last-child) {
  margin-bottom: 48px;
}

.initial__desc {
  margin-bottom: 16px;
}

@media (max-width: 950px) {
  .initial__desc, .initial__form {
    display: none;
  }
}

.initial__mobile {
  width: 100%;
  padding-right: 16px;
  position: relative;
  top: 96px;
}

@media (min-width: 951px) {
  .initial__mobile {
    display: none;
  }
}

.initial__mobile .c-section:not(:last-child) {
  margin-bottom: 48px;
  padding-left: 48px;
}

.initial__mobile .c-marquee {
  width: fit-content;
  will-change: transform;
  animation: marquee 25s linear infinite;
  display: flex;
  position: relative;
}

.initial__mobile .c-marquee__section:not(:first-of-type) {
  padding: 0 1px;
}

.initial__mobile .c-marquee__container {
  width: 100%;
}

.initial__block.active {
  opacity: 1;
}

@keyframes marquee {
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}

@media (min-width: 951px) {
  .page__wrapper {
    padding-bottom: 160px;
  }
}

/*# sourceMappingURL=index.d79a2b56.css.map */
