$white: #ffffff;
$black: #0d0c08;
$link-bg: #171405;
$yellow: #e6c72e;

$ease-out-expo: cubic-bezier(0.19, 1, 0.22, 1);
$font-sans: "Roboto Mono", monospace;

$z-indexes: (
  "noscript",
  "preloader",
  "navigation",
  "content",
  "sidebar",
  "smoke",
  "canvas",
  "4",
  "3",
  "2",
  "1"
);
