html {
  box-sizing: border-box;
  font-size: calc(100vw / 1440 * 10);
  height: 100%;
  // left: 0;
  // overflow: hidden;
  overscroll-behavior: none;
  // position: fixed;
  -moz-osx-font-smoothing: grayscale;
  // top: 0;
  -webkit-font-smoothing: antialiased;
  width: 100%;

  @include media("<=phone") {
    font-size: calc(100vw / 375 * 10);
  }
}

// resets
body {
  margin: 0;
  padding: 0;
  font-family: $font-sans;
  font-size: 12px;
  line-height: 1.3;
  height: 100%;
  overscroll-behavior: none;
  // overflow: hidden;
  // position: fixed;
  // top: 0;
  // left: 0;
  width: 100%;
  background: $black;
  color: $yellow;
}

.c-container {
  @include media(">desktop-sm") {
    max-width: 484px;
  }
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-32 {
  margin-bottom: 32px;
}

.content {
  position: relative;

  .pages {
    position: relative;
  }

  @include media(">desktop-sm") {
    height: 100%;
    z-index: z("content");
    margin-left: auto;
    max-width: toRem(720);
    width: calc(100% - 580px);
    padding-top: 32px;
    padding-right: 32px;
  }
}

.flash {
  animation: blinker 1s step-start infinite;
}

.c-characters {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
