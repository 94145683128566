.c-sidebar {
  @extend %cover;
  position: fixed;
  z-index: z("sidebar");
  padding-top: 32px;
  padding-left: 32px;

  @include media("<=desktop-sm") {
    height: 80px;
    padding: 16px;
    background: linear-gradient(180deg, #0d0c08 0%, rgba(13, 12, 8, 0) 100%);
  }

  &__characters {
    overflow-wrap: break-word;
    @extend %ml-32;

    @include media("<=desktop-sm") {
      display: none;
    }
  }

  &__nav {
    opacity: 0;
    transition-delay: 250ms;

    @include media(">desktop-sm") {
      margin-bottom: 32px;
      transition-delay: unset;
    }
  }

  &__about {
    margin-top: 32px;
    @extend %ml-32;

    @include media("<=desktop-sm") {
      display: none;
    }
  }

  &__links {
    margin-top: 48px;
    @extend %ml-32;

    @include media("<=desktop-sm") {
      display: none;
    }
  }
}
