.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: z("preloader");
  padding: 32px;
  background: $black;

  &__block {
    opacity: 0;

    &.active {
      opacity: 1;
    }
  }
}
